.mybtn {
  height: 50px;
  font-size: 22px;
  font-weight: 500;
  color: black;
  background: #36D1DC;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #5B86E5, #36D1DC);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #5B86E5, #36D1DC); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}


body.modal-open .background-container{
  -webkit-filter: blur(4px);
  -moz-filter: blur(4px);
  -o-filter: blur(4px);
  -ms-filter: blur(4px);
  filter: blur(4px);
}
.card{
    border: none;
}

#img1{
  display: block;
}
#img2{
  display: none;
}

.myimg{
  height: 30rem;
}

.my_cardbody{
  box-shadow:1px 1px 10px 1px  gray ;
}

@media screen and (max-width: 600px) {
  .myimg{
    height: 75vw;
  }
  
#img1{
  display: none;
}
#img2{
  display: block;
}
}

.title{
  background-color: #00b4d8;
  border-radius: 25px 25px 25px 25px;
  color: white;
  font-family:Verdana, Geneva, Tahoma, sans-serif;
  display: block;
}
p{
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
h4{

    font-family: Verdana, Geneva, Tahoma, sans-serif;
  
}




